import gsap from "gsap";

class AnimateHeader {
    constructor() {
        // Check if the 'navShown' key exists in sessionStorage
        if (!sessionStorage.getItem('navShown')) {
            // If it doesn't exist, animate the header in
            gsap.to('.mn-hdr', { top: 0, duration: 1, delay: .4 });

            // Set the 'navShown' key in sessionStorage to prevent future animations
            sessionStorage.setItem('navShown', 'true');
        }
        else {
            const header = document.querySelector('.mn-hdr');
            header.classList.remove('-top-header');
            header.classList.add('top-0');
        }
    }
}

export default new AnimateHeader();
