class HeaderScroller {
    constructor() {
        this.header = document.querySelector('[data-header-scroll]');
        this.headerHeight = this.header ? this.header.offsetHeight : 0;
        this.lastScrollY = window.scrollY;
        this.currentTransform = 0;

        if (!this.header) {
            console.warn(`Header element not found for selector: ${this.header}`);
            return;
        }

        this.init();
    }

    init() {
        window.addEventListener('scroll', this.onScroll.bind(this));
        window.addEventListener('resize', this.onResize.bind(this));
        this.updateHeaderHeight();
    }

    updateHeaderHeight() {
        this.headerHeight = this.header.offsetHeight;
    }

    onScroll() {
        const currentScrollY = window.scrollY;
        const delta = currentScrollY - this.lastScrollY;
        this.lastScrollY = currentScrollY;

        // Move up on scroll down, move down on scroll up
        this.currentTransform = Math.min(Math.max(this.currentTransform - delta, -this.headerHeight), 0);
        this.header.style.transform = `translateY(${this.currentTransform}px)`;
    }

    onResize() {
        this.updateHeaderHeight();
    }
}

export default new HeaderScroller();
