// Imports
import Flickity from 'flickity';
import reframe from 'reframe.js';

// Project import
import Appear from '@js/modules/Appear';
import AppearGrid from '@js/modules/AppearGrid';
import AppearParent from '@js/modules/AppearParent';
import Marquee from '@js/modules/Marquee';
import Earth from '@js/modules/Earth';
import Parallax from '@js/modules/Parallax';
import PinnedBg from '@js/modules/PinnedBg';
import Portfolio from '@js/modules/Portfolio';
import RevealText from '@js/modules/RevealText';
import SplitText from '@js/modules/SplitText';
import Strategy from '@js/modules/Strategy';

class Page {
    constructor() {}

    // Initialize the appear functionality
    init() {
        this.firstLoad();
        this.dynamicLoad();
    }
    reinit() {
        this.dynamicLoad();
    }

    firstLoad() {
        SplitText.init();
    }

    dynamicLoad() {
        /**
         * Reframe JS
         * Make elements like iframes scale at a fixed ratio
         */
        reframe('iframe');

        // If the user does not have reduced motion settings in the browser
        if (!window.REGENERATION.env.reducedMotion) {
            Appear.init();
            AppearGrid.init();
            AppearParent.init();
        }

        Earth.init();
        Marquee.init();
        Parallax.init();
        PinnedBg.init();
        Portfolio.init();
        RevealText.init();
        Strategy.init();

        // Portfolio carousels
        const portfolioCarousels = document.querySelectorAll('.portfolio-carousel');
        portfolioCarousels.forEach(carousel => {
            var flkty = new Flickity(carousel, {
                cellAlign: 'left',
                contain: true,
                draggable: false,
                prevNextButtons: true,
                pageDots: true,
                wrapAround: true,
            });
        });
    }
}

export default new Page();
